.mailchimp-form label {
  color: white;
}
.mailchimp-form form {
  position: relative;
}
.mailchimp-form form label {
  font-size: 16px;
  text-transform: uppercase;
  padding-right: 5px;
}
.mailchimp-form form input {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  padding-left: 20px;
}
.mailchimp-form form input:focus {
  outline: none;
}
.mailchimp-form .error {
  color: white;
  position: absolute;
  left: 10px;
  border: 1px solid black;
  background-color: black;
  padding: 5px;
  border-radius: 5px;

  animation: slideup 4s forwards;
}
.mailchimp-form div {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
}
.footer .mailchimp-form-button-wrapper {
  padding-left: 10px;
}
.footer form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.popup .mailchimp-form {
  width: 400px;
  background-color: black;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 10px 10px 40px rgb(0 0 0 / 80%);
}
.popup .mailchimp-form .close-button {
  position: absolute;
  right: 24px;
  font-size: 24px;
  font-weight: 700;
  top: 12px;
  cursor: pointer;
}
.popup .mailchimp-form .error {
  animation: slidedown 4s forwards;
}
.popup .mailchimp-form form {
  width: 70%;
}
.popup .mailchimp-form label {
  display: none;
}
.popup .mailchimp-form input {
  width: 100%;
  margin-top: 10px;
}
.popup .mailchimp-form .subscribe-info {
  width: 70%;
  text-align: center;
}
.popup .mailchimp-form .success-message {
  width: 70%;
  text-align: center;
}
.popup .mailchimp-form-button-wrapper {
  justify-content: center;
  width: 100%;
  display: flex;
  margin-top: 15px;
}

@keyframes slideup {
    0% {
        top: -25px;
        opacity: 0.75;
    }
    25% {
        top: -35px;
        opacity: 1;
    }
    75% {
      top: -35px;
      opacity: 1;
    }
    85% {
      opacity: 0.95;
    }
    90% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.4;
    }
    100% {
        top: -35px;
        opacity: 0.1;
        visibility: hidden;
    }
}
@keyframes slidedown {
  0% {
      top: 40px;
      opacity: 0.75;
  }
  25% {
      top: 50px;
      opacity: 1;
  }
  75% {
    top: 55px;
    opacity: 1;
  }
  85% {
    opacity: 0.95;
  }
  90% {
    opacity: 0.7;
  }
  95% {
    opacity: 0.4;
  }
  100% {
      top: 55px;
      opacity: 0.1;
      visibility: hidden;
  }
}

@media only screen and (max-width: 555px) {
  .popup .mailchimp-form {
    width: 350px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer form label {
    flex-basis: 100%;
    text-align: center;
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .popup .mailchimp-form {
    width: 300px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .footer .mailchimp-form form input {
    /* flex-basis: 100%; */
    flex-basis: 60%;
    margin-bottom: 5px;
  }
}

