header.padding {
  padding-bottom: 10px;
  padding-top: 20px;
}
.mobile-header-padding {
  padding-bottom: 30px;
}

.navWrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 60px;
}

.image {
  height: 70px;
  width: 135px;
}
.imageMobile {
  margin-top: 20px;
}

.linksWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.topNavLink {
  text-decoration: none;
  color: black;
  margin-left: 40px;
}
.topNavLink:hover {
  opacity: 0.7;
}

.linksWrapper button h4 {
  font-size: 24px;
}
.linksWrapper button:hover {
  opacity: 0.7;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1260px) {
  header.wrap {
    padding-right: 20px;
    width: calc(98% - 20px);
  }
}
@media only screen and (max-width: 850px) {
  header.wrap {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 899px) {
  .navWrapper .linksWrapper .topNavLink.heading {
    font-size: 20px !important;
  }
  .navWrapper .linksWrapper h4 {
    font-size: 20px !important;
  }
}
@media only screen and (max-height: 600px) {
  .bm-item-list .bm-item {
    font-size: 28px;
  }
}
