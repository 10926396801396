.verticalSections {
  display: flex;
  width: 95%;
}
.verticalSections .articles-wrapper {
  width: 75%;
}
.verticalSections .article {
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-decoration: none;
  color: black;
}
.verticalSections .article div:first-child, .verticalSections .full-article {
  flex: 1;
  margin-right: 20px;
}
.verticalSections .article div:last-child {
  margin-right: 10px;
}
.verticalSections .article p {
  margin-bottom: 0;
}
.verticalSections .article .article-image {
  width: 250px;
  border-radius: 10px;
}
.verticalSections .article-image-wrapper {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.verticalSections .article-image-wrapper img {
  border-radius: 10px;
  box-shadow: 10px 10px 40px rgb(0 0 0 / 40%);
}
.verticalSections .related-articles-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.verticalSections .related-article {
  width: 100%;
  margin-top: 15px;
  text-decoration: none;
  color: #000;
}
.verticalSections .related-article:hover {
  opacity: 0.5;
}
.verticalSections .related-article-row {
  display: flex;
}
.verticalSections .related-article-row p {
  margin: 5px 0 0 5px;
}
.verticalSections .related-article-image {
  height: 80px;
  border-radius: 5px;
}


.verticalSections .discover {
  width: 30%;
}
.verticalSections .discover-wrapper {
  padding-left: 15px;
  position: sticky;
  top: 20px;
}
.verticalSections .keywords-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.verticalSections .keywords-wrapper .keyword-link {
  color: #6a6b6c;
  text-decoration: none;
  margin: 5px;
}
.verticalSections .keywords-wrapper .keyword-link:hover {
  /* background-color: rgba(0,0,0,0.5); */
  opacity: 0.7;
}
.verticalSections .keywords-wrapper .selected-keyword {
  background-color: #000;
  color: #fff;
  border-radius: 3px;
}
.verticalSections .keywords-wrapper .keyword {
  border: 1px rgba(0,0,0,0.5) solid;
  border-radius: 3px;
  padding: 8px;
}

.article-link-to-ep {
  text-decoration: none;
}

.verticalSections .article p.article-sub-heading {
  color: #6a6b6c;
}

.single-article-content .full-article p.mediumHeading {
  font-size: 22px;
}
.single-article-content .full-article p.article-content {
  font-size: 18px;
}
.single-article-content .article-image-wrapper {
  justify-content: flex-start;
}
.single-article-content .article-image {
  max-width: 500px;
  max-height: 500px;
}

.no-article-for-slug {
  font-size: 24px;
  margin-top: 20px;
  color: #6a6b6c;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.article-loading {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@media (max-width: 899px) {
  /* Articles */
  .articles-content {
    width: 100%;
  }
  .articles-content .discover-wrapper {
    padding-left: 10px;
  }
  .articles-content .articles-wrapper {
    width: calc(75% - 20px);
    padding-left: 20px;
  }
  .articles-content .article .article-image {
    width: 200px;
  }

  /* Article */
  .single-article-content {
    width: 97%;
  }
  .single-article-content .full-article p.largeHeading {
    font-size: 32px;
    margin-bottom: 0;
  }
  .single-article-content .full-article p.mediumHeading {
    font-size: 20px;
  }
  .single-article-content .full-article p.article-content {
    font-size: 14px;
  }
  .single-article-content .article-image {
    max-width: 400px;
    max-height: 400px;
  }
}
@media (max-width: 768px) {
  /* Article */
  .single-article-content {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  .single-article-content .full-article {
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .single-article-content .related-articles {
    width: 95%;
  }
  .single-article-content .related-articles .discover-wrapper {
    padding: 0;
  }
  .single-article-content .related-articles .related-article {
    padding-right: 25px;
    width: calc(50% - 25px);
  }
  .single-article-content .related-articles .related-article:nth-child(2n + 2) {
    padding-right: 0px;
    padding-left: 25px;
  }
}
@media (max-width: 729px) {
  .articles-content {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
  }
  .articles-content .discover {
    width: 90%;
    flex: 1;
  }
  .articles-content .articles-wrapper {
    width: 90%;
    flex: 1;
  }
}
@media (max-width: 599px) {
  /* Article */
  .single-article-content .related-articles .related-article {
    width: 80%;
    padding: 0;
  }
  .single-article-content .related-articles .related-article:nth-child(2n + 2) {
    padding: 0;
  }
  .single-article-content .related-articles-wrapper {
    justify-content: center;
  }
  .single-article-content .related-articles p.mediumHeading {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .articles-content .article .article-image {
    width: 150px;
  }
  .articles-content .article p.mediumHeading {
    font-size: 20px;
  }
  .articles-content .article p.article-sub-heading, .articles-content .article p.article-date {
    font-size: 14px
  }

  /* Article */
  .single-article-content .article-image {
    max-width: 350px;
    max-height: 350px;
  }
  .single-article-content .related-articles .related-article {
    width: 90%;
  }
  .single-article-content .full-article {
    width: 90%;
  }
  .single-article-content .full-article  p.largeHeading {
    text-align: center;
  }
}
@media (max-width: 400px) {
  .articles-content .article .article-image {
    width: 125px;
  }

  /* Article */
  .single-article-content .article-image {
    max-width: 300px;
    max-height: 300px;
  }
}
@media (max-width: 325px) {
  /* Article */
  .single-article-content .article-image {
    max-width: 275px;
    max-height: 275px;
  }
}
