.book-a-talk-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 65%;
  justify-content: center;
}

.book-a-talk-preamble {
  width: 60%;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  display: flex;
}
.book-a-talk-preamble p {
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  padding-bottom: 10px;
}

.book-a-talk-spacer {
  flex: 1;
  min-height: 20px;
  width: 100%;
}
.book-a-talk-spacer p {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

.book-a-talk-success {
  margin-top: 20px;
  font-size: 26px;
}

.book-a-talk-form-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.book-a-talk-form-item-wrapper {
  width: 50%;
  text-align: center;
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}

.required-field {
  border-color: red;
}
.required-field-text {
  color: red;
  position: absolute;
  top: -20px;
  font-size: 18px;
  font-family: 'Arial Narrow', 'Arial';
}

.book-a-talk-form-item-wrapper input, .book-a-talk-form-item-wrapper select {
  width: 80%;
  background-color: #000;
  color: white;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
}
.book-a-talk-form-item-wrapper select {
  width: 90%;
}
.book-a-talk-form-item-wrapper input::placeholder, .book-a-talk-form-item-wrapper textarea::placeholder {
  color: white;
  font-family: 'Arial Narrow', 'Arial';
  text-transform: uppercase;
  font-size: 16px;
}
.book-a-talk-form-item-wrapper textarea {
  border-radius: 5px;
  background-color: #000;
  color: white;
  padding: 15px;
  min-height: 150px;
  resize: vertical;
  font-family: 'Arial Narrow', 'Arial';
  font-size: 16px;
}
.book-a-talk-form-item-wrapper textarea::placeholder {
  color: white;
  font-size: 16px;
}

.book-a-talk-form-item-wrapper-full-width {
  width: 100%;
}
.book-a-talk-form-item-wrapper-full-width input, .book-a-talk-form-item-wrapper-full-width textarea {
  width: 90.5%;
}

.book-a-talk-form-bottom {
  margin-bottom: 0;
}
.book-a-talk-form-bottom button {
  width: calc(100% / 3);
  padding: 15px;
  font-size: 16px;
}

@media only screen and (max-width: 899px) {
  .book-a-talk-section, .book-a-talk-preamble {
    width: 80%;
  }
}
@media only screen and (max-width: 799px) {
  .book-a-talk-section, .book-a-talk-preamble {
    width: 90%;
  }
}
@media only screen and (max-width: 749px) {
  .book-a-talk-section {
    width: 95%;
  }
}
@media only screen and (max-width: 549px) {
  .book-a-talk-form-item-wrapper {
    width: 100%;
  }
  .book-a-talk-form-wrapper {
    justify-content: center;
  }
  .book-a-talk-form-item-wrapper-full-width input, .book-a-talk-form-item-wrapper-full-width textarea {
    width: 80%;
  }
  .book-a-talk-form-item-wrapper select {
    width: 87%;
  }
  .book-a-talk-form-bottom button {
    width: 50%;
  }
}
@media only screen and (max-width: 400px) {
  .book-a-talk-form-item-wrapper select {
    width: 89%;
  }
}