.cardWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}
.cardWrapper .article-image-wrapper {
  height: 250px;
}
.cardWrapper .article-image {
  height: 100%;
  width: 100%;
}

.ppBGBox {
  height: 95%;
  border-radius: 15px;
  position: absolute;
  right: 25px;
  width: calc(100% - 200px);
  background-color: #000000;
}

.individualCard {
  width: calc(25% - 40px);
  padding: 20px;
  position: relative;
  text-decoration: none;
  color: black;
}
.individualCard:hover .episodeImage {
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  filter: blur(8px);
}
.individualCard:hover .card-content {
  opacity: 1;
}

.latestEpImage {
  max-width: 480px;
  max-height: 480px;
  margin-left: 50px;
}
.latestEpWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* Offsetting the padding */
  padding-bottom: 70px;
}
.latestEpText {
  color: #fff;
}
.latestEpTextParagraph {
  width: 80%;
}
.see-all-episodes {
  margin-bottom: 10px;
}

@media (max-width: 899px) {
  .individualCard {
    width: calc(33% - 40px);
  }

  .latestEpWrapper .latestEpText.heading {
    text-align: center;
  }

  .latestEpImage {
    max-width: 384px;
    max-height: 384px;
  }
}
@media (max-width: 768px) {
  .latestEpImage {
    max-width: 320px;
    max-height: 320px;
  }

  .latestEpWrapper .latestEpText.largeHeading {
    font-size: 32px;
  }

  .home-latest-episode .ppBGBox {
    width: calc(100% - 300px);
    height: 90%;
  }
}
@media (max-width: 667px) {
  .latestEpImage {
    max-width: 290px;
    max-height: 290px;
  }

  .individualCard {
    width: calc(50% - 40px);
  }

  .latestEpImage {
    margin-left: 25px;
  }

  .latestEpWrapper .latestEpText.largeHeading {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .latestEpWrapper .latestEpText.heading {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
@media (max-width: 554px) {
  .home-latest-episode .ppBGBox {
    display: none;
  }

  .home-latest-episode .innerContent .contentBody {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 0px;
  }

  .home-latest-episode .latestEpImage {
    max-width: 90%;
    max-height: 90%;
    margin-left: 0px;
  }

  .home-latest-episode .innerContent .contentBody .latestEpWrapper {
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* Offsetting the padding */
    padding-bottom: 10px;
  }
  .home-latest-episode .innerContent .contentBody .latestEpWrapper h2, .content .innerContent .contentBody .latestEpWrapper h4 {
    color: #000;
  }
  .home-latest-episode .innerContent .contentBody .latestEpWrapper button {
    background-color: #000;
  }
  .home-latest-episode .innerContent .contentBody .latestEpWrapper button h4 {
    color: #fff;
  }
  .home-latest-episode .innerContent .contentBody .latestEpWrapper .view-episode-button {
    display: none;
  }

  .latestEpWrapper .latestEpText.largeHeading {
    font-size: 32px;
  }
  .latestEpWrapper .latestEpText.heading {
    font-size: 24px;
    margin-top: 5px
  }
  .latestEpWrapper h4.latestEpText {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .latest-ep-img-wrapper {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 485px) {
  .individualCard {
    width: calc(80% - 40px);
  }
  .cardWrapper {
    justify-content: center;
  }
}
@media (max-width: 380px) {
  .individualCard {
    width: calc(95% - 40px);
  }
}
