/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 48px !important;
  width: 48px !important;
  right: 24px !important;
  top: 12px !important;
}
.bm-cross-button span {
  position: absolute;
  top: 6px !important;
  right: 11px !important;
}

/* Color/shape of close button cross */
.bm-cross-button span .bm-cross {
  background: #bdc3c7;
  width: 6px !important;
  height: 28px !important;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(55,58,71, 0.95);
  padding: 1.5em 1.5em 0;
  font-size: 2em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0);
  width: 0 !important;
}

/* Burger Menu Item */

/* Individual item */
.bm-item {
  text-decoration: none;
  margin: 1em;
  color: #fff;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}
