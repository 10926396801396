.subHeadingWrapper {
  width: 98%;
  text-align: left;
  border-bottom: 1px solid #3E4355;
}

.subHeadingText {
  font-size: 2rem;
  margin: 1rem 0 0.5rem 0;
}
