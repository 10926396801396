.contact-section {
  text-align: left;
  width: 95%;
  padding-top: 5px;
}
.contact-section h2 {
  font-weight: 400;
  line-height: 1.75;
}
.contact-section .details-wrapper h2 {
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-all;
}
.contact-section .socials-icons {
  margin-top: 20px;
  display: flex;
}
.contact-section .socials-icons a {
  margin-left: 5px;
  margin-right: 5px;
  background-color: black;
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
}
.contact-section .socials-icons .insta img {
  height: 36px;
  width: 36px;
}
.contact-section .socials-icons .fb img {
  height: 36px;
  width: 20px;
}

@media only screen and (max-width: 669px) {
  .contact-section h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 470px) {
  .contact-section h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 375px) {
  .contact-section h2 {
    font-size: 16px;
  }
}

