/* Text */
body {
  font-family: 'Arial Narrow', Arial, sans-serif;
}
.heading {
  font-family: 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
}
.mediumHeading {
  font-family: 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  font-size: 1.5em;
}
.largeHeading {
  font-family: 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  font-size: 2.5em;
}
.grey-text {
  color: #757575;
}

.wrap {
  width: 1180px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.fullWidth {
  width: 100%;
}

.content {
  width: 100%;
  height: 100%;
}
.innerContent {
  position: relative;
  margin-top: 20px;
}

.contentBody {
  z-index: 2;
  position: relative;
  padding-top: 30px;

  display: flex;
  flex-direction: row;
}

.imageRounded {
  border-radius: 15px;;
}

.arrow {
  height: 20px;
  width: 20px;
}
.fullHeight {
  height: 100%;
}

.noSearchResults {
  color: gray;
  padding-left: 10px;
  font-size: 24px;
}

/* Loading transitions */
body {
  /* Initial page load fade */
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.app {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}