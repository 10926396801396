.card {
  text-decoration: none;
  color: black;
  position: relative;

  height: 100%;
}
.card .episodeImage {
  box-shadow: 10px 10px 40px rgb(0 0 0 / 80%);
  height: 100%;
  width: 100%;
  border-radius: 15px;

  transition: all 0.5s linear;
}

.card-content {
  opacity: 0;
  z-index: 3;
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
  transition: opacity 0.8s linear
}
