.loading-spinner-content-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot1, .dot2, .dot3 {
  background: #fff;
  width: 5px;
  height: 5px;
  border: double;
  border-color: white;
  border-radius: 50%;
  margin: 10px;
}
.dot1 {
  animation: jump 1.6s -0.32s linear infinite;
  background: white;
}
.dot2 {
  animation: jump 1.6s -0.16s linear infinite;
  background: white;
}
.dot3 {
  animation: jump 1.6s linear infinite;
  background: white;
}

@keyframes jump {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(2.0);
    transform: scale(2.0);
  }
}
