button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  border: 0;
}
button:hover {
  opacity: 0.8;
}
button h4 {
  font-size: 1.25em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Arial Narrow', 'Arial';
}
