.footer {
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: black;
}
.footer .footer-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}
.footer .footer-content .form-padding {
  flex: 1;
}
.footer .footer-content p {
  color: white;
}
.footer .footer-content img {
  height: 36px;
}
.footer .footer-content .socials-wrapper {
  background-color: white;
  border-radius: 20px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.footer .footer-content .socials-wrapper img {
  height: 24px;
}
.footer .footer-content p.copyright-text {
  padding-right: 10px;
  padding-left: 15px;
}

.socials-copyright-wrapper {
  display: flex;
  align-items: center;
}

.form-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
}

@media (max-width: 899px) {
  .footer-content {
    flex-wrap: wrap;
  }
  .form-wrapper {
    width: 100%;
  }
  .socials-copyright-wrapper {
    width: 100%;
    justify-content: center;
  }

  .footer {
    height: 120px;
  }
}
@media (max-width: 485px) {
  .footer .mailchimp-form input {
    width: 175px;
  }
}
@media (max-width: 360px) {
  .footer {
    height: 165px;
  }
}
