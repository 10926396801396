.searchWrapper {
  margin-top: 1em;
  width: 98%;
}
.searchWrapper input {
  border-radius: 10px;
  border: 1px solid darkgray;
  width: 150px;
  padding: 10px;
}

.smallerImg {
  max-width: 480px;
  max-height: 384px;
}
.placeholderDiv {
  width: 480px;
  height: 384px;
}
.placeholderDiv p {
  text-align: center;
  font-size: 28px;
  margin-top: 40px;
  color: #6a6b6c;
}

.iconRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.iconRowItem {
  display: flex;
  align-items: center;
}
.iconWrapper {
  margin-left: 5px;
}
.thirdPartyLogo {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
}
.firstLogo {
  margin-left: 10px;
}
.arrowPaddingBottom {
  padding-bottom: 20px;
}

.popup-wrapper {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: left;
  background: rgba(0,0,0,0.85);
}
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.episode-content .latestEpText.heading {
  margin: 0;
  font-size: 28px;
}
.episode-content h4 {
  margin-top: 10px;
}
.episode-content .latest-ep-bg {
  display: none;
}

.scroll-to-top {
  position: fixed;
  background-color: black;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 10;
  padding: 0px 15px;
  border-radius: 10px;
  cursor: pointer;

  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.scroll-to-top:hover {
  opacity: 0.6;
}
.scroll-to-top p {
  padding-left: 10px;
  color: white;
  font-size: 24px;
}

.expand-arrow-wrapper {
  cursor: pointer;
}

@media only screen and (max-width: 949px) {
  .episode-content .latestEpImage {
    margin-left: 25px;
    max-width: 400px;
    max-height: 320px;
  }
  .episode-content .iconRow {
    width: 90%;
  }
  .episode-content .iconRow img {
    margin-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .searchWrapper {
    margin-top: 0.5em;
  }

  .episode-content .ppBGBox {
    display: none;
  }
  .episode-content .innerContent {
    margin-top: 0;
  }
  .episode-content .contentBody {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 0;
    align-items: center;
  }
  .episode-content .latestEpImage {
    margin: 0;
    z-index: 3;
    max-width: 480px;
    max-height: 384px;
  }
  .episode-content .latestEpWrapper {
    padding-top: 15px;
    position: relative;
    z-index: 2;
    max-width: 80%;
  }
  .episode-content .latest-ep-bg {
    display: block;
    position: absolute;
    height: 100%;
    border-radius: 15px;
    background-color: #000;
    width: calc(80%);
    width: 100%;
    z-index: -3;
  }
}
@media only screen and (max-width: 555px) {
  .episode-content .latestEpImage {
    max-width: 75%;
  }
  .episode-content .latestEpWrapper {
    max-width: 95%;
  }
  .episode-content .latestEpWrapper h4.latestEpText {
    color: white !important;
  }
  .iconRow {
    flex-wrap: wrap;
  }
  .iconRowItem {
    width: 60%;
    justify-content: center;
  }
}


