.app {
  /* 90px is height of header */
  min-height: calc(100vh - 90px);
  display: block;
  position: relative;
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
}

.appBody {
  height: 100%;
  display: flex;
  padding-bottom: 80px; /* Height of footer */

  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (max-width: 899px) {
  .appBody {
    padding-bottom: 140px; /* Height of footer */
  }
}

@media (max-width: 360px) {
  .appBody {
    padding-bottom: 165px; /* Height of footer */
  }
}
